<template>
  <div class="submitted-container">
    <div class="title-container"><h2>Order Successfully Submitted!</h2></div>
    <a-button @click="toUserInfoPage">New Order</a-button>
  </div>
</template>

<script>
import {editCartInfo} from "@/req-api-common";

export default {
  name: "submitted",
  methods: {
    toUserInfoPage() {
      localStorage.removeItem('itemNo')
      localStorage.removeItem('userinfo')
      localStorage.removeItem('orderInfo')
      editCartInfo()
      const isMobile = localStorage.getItem('isMobile')
      const url = isMobile ? '/?isMobile=true' : '/'
      this.$router.push(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.submitted-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;

  .title-container {
    padding: 30vh 0 18vh;
  }

  .ant-btn {
    min-width: 66%;
    height: 50px;
    font-size: 18px;
  }
}
</style>